const { CO_PILOT_TOKEN } = process.env;
const CO_PILOT_TOKEN_VALUE = CO_PILOT_TOKEN || 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwZjAzZjhmYjM5MTNjMDAwODAwNGUzZSIsInJvbGVzIjpbIkFkbWluIl0sInBlcm1pc3Npb25zIjpbIndyaXRlOmNvbGxlY3Rpb24iLCJ3cml0ZTpyb2xlIiwicmVhZDpyb2xlIiwicmVhZDphdHRyaWJ1dGUtZ3JvdXAiLCJ3cml0ZTpza3VzZXQiLCJ3cml0ZTphdHRyaWJ1dGUtZ3JvdXAiLCJ3cml0ZTp1c2VyIiwid3JpdGU6YXR0cmlidXRlIiwicmVhZDpwZXJtaXNzaW9uIiwicmVhZDpza3VzZXQiLCJyZWFkOmF0dHJpYnV0ZSIsInJlYWQ6Y29sbGVjdGlvbiIsInJlYWQ6dXNlciJdLCJhY2NvdW50IjoiNWYzMjhiZjBiN2MxNTcwMDA3MTIzM2I5IiwiYWNjb3VudElkIjo4NzM5MzkyMjk0LCJ1c2VyVHlwZSI6eyJraW5kIjoiUkVHSVNURVJFRCJ9LCJpYXQiOjE2MjY3MTYxMTZ9.UlqpND2c1F-74LPba5QIeUplvIWZ6Q_ncy3v2JmdV949ITnG07r5F1b1pEFCxxwxqBMy-EvxsV323vz1VNZHTw';

const constants = {
  API_CALL_TYPE: {
    POST: 'post',
    GET: 'get',
    PUT: 'put',
    DELETE: 'delete',
    PATCH: 'patch',
  },
  USER_DETAILS_KEY: 'user-copilot',
  USER_AUTH_KEY: 'auth',
  USER_BRAND_ID_KEY: 'brand_id',
  USER_STORE_ID_KEY: 'store_id',
  USER_BRAND_KEY: 'brand_info',
  SELL_CURRENT_INVOICE_KEY: 'current_invoice',
  SELL_INVOICE_QUEUE_KEY: 'invoice_queue',
  X_API_KEY: "X-API-KEY",
  STOCK_DATE_RANGE: "stock_date_range",
  SUPER_ADMIN_DATE_RANGE: "super_admin_date_range",
  CO_PILOT_LIFE_TIME_TOKEN: CO_PILOT_TOKEN_VALUE,
  APP_ROUTES_BASE_URL: '/pos',
  OFFLINE_DASHBOARD_RES: 'dashboard_response',
  OFFLINE_GRAPH_RES: 'graph_response',
  OFFLINE_RECOMENDATION_RES: 'recomendation_response',
  OFFLINE_RECOMENDATION_PRODUCTS_RES: 'recomendation_products_response',
  OFFLINE_SELL_BRAND_RES: 'sell_brand_response',
  OFFLINE_SELL_STORE_RES: 'sell_store_response',
  OFFLINE_SELL_TEMPLATE_RES: 'sell_template_response',
  OFFLINE_SELL_CUSTOMER_RES: 'sell_customer_response',
  OFFLINE_SELL_PRODUCTS_RES: 'offline_sell_products',
  ONLINE_SELL_PRODUCTS_RES: 'online_sell_products',
  ONLINE_SELL_PRODUCTS_RES: 'online_sell_products',
  OFFLINE_SELL_INVOICE_DATA: 'offline_sell_invoice_data',
  OFFLINE_SELL_INVOICE_OLD_DATA: 'offline_sell_invoice_old_data',
  OFFLINE_ADD_CUSTOMER_DATA: 'offline_add_customer_data',


  SETUP: {
    USERS_TAB_KEY: 'users',
    OUTLETS_TAB_KEY: 'outlets',
    RECEIPTS_TAB_KEY: 'receipts-templates',
    ROLES_TAB_KEY: 'user-roles',
    BRANDS_TAB_KEY: 'brands',
    CONFIGURATIONS_TAB_KEY: 'configurations',
  },
  
  STOCK_CONTROL: {
    PURCHASE_ORDER: 'purchase-orders',
    STOCK_REQUEST: 'stock-request',
    STOCK_ADJUSTMENTS: 'stock-adjustments',
    RETURN_STOCK: 'returned-stock',
    FORCE_CLOSED: 'force_closed',
    RECEIVED: 'received',
    OPEN: 'Open',
    WAITING_FOR_ADMIN_APPROVAL: 'waiting_for_admin_approval',
    REJECTED: 'rejected',
    INVENTORY_TRANSFER: 'Inventory Transfers',
    STATUSES: {
      OPEN: 'open',
      PARTIAL_RECEIVED: 'partial received',
      PENDING: 'pending',
      TRANSIT: 'in transit',
      LOST: 'lost in transit'
    }
  },

  REGISTER_SALES_HISTORY: {
    CONTINUE_TAB_KEY: 'continue-sales',
    RETURNS_TAB_KEY: 'returned-sales',
    COMPLETED_TAB_KEY: 'completed-sales',
    DEAD_TAB_KEY: 'dead-sales',
    ALL_TAB_KEY: 'all-sales',

    INVOICE_STATUSES: {
      COMPLETED : {
        KEY: '0',
        VALUE: 'completed',
      },
      RETURNED_COMPLETED : {
        KEY: '2',
        VALUE: 'Return, completed',
      },
      PARKED : {
        KEY_PARKED: '1',
        KEY_DEAD: '0',
        VALUE: 'Parked',
      },
      DEAD : {
        KEY_PARKED: '1',
        KEY_DEAD: '1',
        VALUE: 'Dead',
      },

    }
  },
  
  ALL_MODULE_NAMES: {
    CATEGORIES: "categories",
    COURIERS: "couriers",
    TAXES: "taxes",
    SUPPLIERS: "suppliers",
    PRODUCTS: "products",
    CUSTOMERS: "customers",
    REGISTER: "register",
    REPORTS: "reports",
    SETUP: "setup",
    STOCK: "stock-control",
    ECOMMERCE: "ecommerce",
    ACTIONS_HISTORY: "actions-history",

    SUB_LEVEL_MODULES: {
      STORES: 'outlets',
      TEMPLATES: 'receipts-templates',
      BRANDS: 'brands',
      USERS: 'users',
      USER_ROLES: 'user-roles',
      ATTRIBUTES: 'attributes',
      ATTRIBUTES_VALUES: 'attribute-values',
      SELL: 'sell',
      SALE_HISTORY: 'sales-history',
      PURCHASE_ORDERS: 'purchase-orders',
      TRANSFERS: 'stock-request',
      STOCK_ADJUSTMENTS: 'stock-adjustments',
      STOCK_RETURNS: 'returned-stock',
      PO_GOOD_RECEIVE_NOTES: 'grn-view',
      CONFIGURATIONS: 'configurations',
      SINGLE_INVOICE_VIEW: 'invoice',
      ORDERS: 'orders',

    }

  },

  SUPER_ADMIN: {
    PURCHASE_ORDER: 'purchase-orders',
    STOCK_REQUEST: 'stock-request',
  },
  
  FILE_TYPES: {
    IMAGES_FILE_TYPES: ["image/x-png","image/gif","image/jpeg", "image/jpg", "image/png", "image/svg", "image/svg+xml"]
  },
  DECIMAL_POINTS: [
    {
      id: 1,
      name: 1,
    },
    {
      id: 2,
      name: 2,
    },
    {
     id: 3,
     name: 3,
   },
   {
     id: 4,
     name: 4,
   },
   {
     id: 5,
     name: 5,
   },
  ],
  DECIMAL_POINTS_PRODUCTS_CONFIGURATIONS: [
    {
      id: 1,
      name: 1,
    },
    {
      id: 2,
      name: 2,
    },
    {
     id: 3,
     name: 3,
   }
  ]
};

export default constants;

export var ROOT_SYNC_SCOPE = false;