import React, { useState, useEffect } from "react";
import { Icon, Button, Dropdown, Timepicker, IconButton } from "@teamfabric/copilot-ui";

// components
import CustomCalendar from "../../atoms/CustomCalendar";
import { filterOptions, hourlyFilterOptions } from "./filter";
import moment from "moment";
const todayDate = moment().format("yyyy-MM-DD");

function DateRangePicker(props) {
  const {
    startDateLabel = "",
    endDateLabel = "",
    isFilter = false,
    buttonText = "",
    filterTitle = "",
    className = "",
    isClearButton=false,
    handleClearFilters,
    // isTime = false,
    isHourly = false,
    selectedDates = ""
  } = props;

  const [calenderStartDate, setCalenderStartDate] = useState(todayDate);
  const [calenderEndDate, setCalenderEndDate] = useState(todayDate);
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[1]);
  const [selectedHour, setSelectedHour] = useState(hourlyFilterOptions[0]);
  const [hourlyInfo, sethourlyInfo] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  // const [startTime, setStartTime] = useState('');
  // const [endTime, setEndTime] = useState('');

  useEffect(() => {
    if(selectedDates.length > 0) {
      if (selectedDates[0] === moment(new Date()).format("yyyy-MM-DD") && selectedDates[1] === moment(new Date()).format("yyyy-MM-DD")) {
        setSelectedFilter(filterOptions[1]);
      } else {
        const findDateFilter = filterOptions.find(
          (i) => i.startDate === selectedDates[0] && i.endDate === selectedDates[1]
        );
        if (findDateFilter) {
          setSelectedFilter(findDateFilter);
        } else {
          setSelectedFilter(filterOptions[0]);
        }
      }
      setCalenderStartDate(selectedDates[0]);
      setCalenderEndDate(selectedDates[1]);
    }
  }, [
    startDateLabel,
    endDateLabel,
    props.onCalenderDateSelect,
    props.onFetchButtonClick,
    props.onHourlySelect,
    props.selectedDates
  ]); //imp to pass showAlert to re render and event handlers as well
  const handleHourlyInfo = () => {
    setShowInfo(!showInfo);
if(!showInfo) {
  sethourlyInfo("Hourly reports only fetch current date's data")
}else {
  sethourlyInfo("")
}

  };
  const handleCalenderChangeStartDateSelect = (dateString) => {
    setCalenderStartDate(dateString);
    const endDate = moment(dateString).add(1, "days").format("yyyy-MM-DD");
    setCalenderEndDate(endDate);
    const findDateFilter = filterOptions.find(
      (i) => i.startDate === dateString && i.endDate === calenderEndDate
    );
    if (findDateFilter) {
      setSelectedFilter(findDateFilter);
    } else {
      setSelectedFilter(filterOptions[0]);
    }
    if (selectedDates.length <= 0) {
      props.onCalenderDateSelect([dateString, endDate]);
    }
  };

  const handleCalenderChangeEndDateSelect = (dateString) => {
    setCalenderEndDate(dateString);
    const findDateFilter = filterOptions.find(
      (i) => i.startDate === calenderStartDate && i.endDate === dateString
    );
    if (findDateFilter) {
      setSelectedFilter(findDateFilter);
    } else {
      setSelectedFilter(filterOptions[0]);
    }
    if (selectedDates.length <= 0) {
      props.onCalenderDateSelect([calenderStartDate, dateString]);
    }
  };

  const onFetchSelectedDatesData = () => {
    const findDateFilter = filterOptions.find(
      (i) => i.startDate === calenderStartDate && i.endDate === calenderEndDate
    );
    if (findDateFilter) {
      setSelectedFilter(findDateFilter);
    } else {
      setSelectedFilter(filterOptions[0]);
    }
    props.onFetchButtonClick([calenderStartDate, calenderEndDate]);
  };

  const handleFilterSelect = (value) => {
    if (value.id !== 0) {
      setCalenderStartDate(value.startDate);
      setCalenderEndDate(value.endDate);
      if (selectedDates.length <= 0) {
        props.onCalenderDateSelect([value.startDate, value.endDate]);
      }
    }
    setSelectedFilter(value);
  };
  const handleHourlySelect =  (value) => {
        setSelectedHour(value);
       props.onHourlySelect(value);

  };
  const onClickClearButton = () => {
    setCalenderStartDate([]);
    setCalenderEndDate([]);
    setSelectedHour(hourlyFilterOptions[0])
    handleClearFilters();
  }
  // const pickTime = (value) => {
  //   const t = moment(value).toISOString();
  //   const modifiedTieme = t.split("T");
  //   const finalTime = "T" + modifiedTieme[1];
  //   setTime(finalTime);
  // };
  return (
    <div className="date_range_picker">
      <CustomCalendar
        previousDate={calenderStartDate}
        text={startDateLabel}
        onCalenderDateSelect={handleCalenderChangeStartDateSelect}
      />
      <Icon iconName="RightArrow" size={16} />
      <CustomCalendar
        maxDate={calenderStartDate}
        previousDate={calenderEndDate}
        text={endDateLabel}
        onCalenderDateSelect={handleCalenderChangeEndDateSelect}
      />
      {/* {
        isTime && (
          <>
          <Timepicker
            // date={currentTime}
            date={null}
            label="Start time"
            onChange={pickTime}
            width="100px"
          />
           <Icon iconName="RightArrow" size={16} />
           <Timepicker
            // date={currentTime}
            date={null}
            label="End time"
            onChange={pickTime}
            width="100px"
          />
</>
        )
      } */}
      <div className="custom__filter">
        {isFilter && (
          <Dropdown
            onSelect={handleFilterSelect}
            options={filterOptions}
            titleLabel={filterTitle || "Filter"}
            type="large"
            value={selectedFilter}
            width="100%"
          />
        )}
      </div>

      {isHourly &&  
      <div className="custom__filter">
        <Dropdown
          onSelect={handleHourlySelect}
          options={hourlyFilterOptions}
          titleLabel={"Hourly"}
          type="large"
          value={selectedHour}
          width="100%"
        />
    </div>}

      <Button
        onClick={onFetchSelectedDatesData}
        size="small"
        text={buttonText || "Fetch"}
      />
      {isHourly && (
        <>
        <IconButton
        icon="Info"
        iconSize={24}
        onClick={handleHourlyInfo}
        onMouseOver={function noRefCheck(){}}
        type=""
      />
      <p>{hourlyInfo}</p>
      </>
      )}
      {isClearButton && (
         <Button
         isPrimary={false}
         onClick={onClickClearButton}
         size="small"
         text={"Clear Filter"}
       />
      )}
    </div>
  );
}

export default DateRangePicker;
