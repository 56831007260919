import React, { useEffect, useState } from 'react';
import { Table } from '@teamfabric/copilot-ui/dist/organisms';
import { Accordion } from '@teamfabric/copilot-ui';
import { numberWithCommas } from '../../../../../utils/helpers/scripts';

const ItemWiseReportTable = ({
  paginationData,
  onClickPageChanger,
  data,
  tableDataLoading,
  currentPageIndex,
}) => {
  const [tableData, setTableData] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  let perPageNo = 10;

  useEffect(() => {
    return renderData();
  }, [currentPageIndex, data]);

  const checkArrayLength = (array) => Array.isArray(array);
  const renderData = () => {
    if (data && data.length > 0) {
      const finalArray = [];
      data.forEach((item, index) => {
        let indexObj = data[index];
        let tempArr = [];

        indexObj.amountEx_formatted = numberWithCommas(indexObj.amountEx);
        indexObj.amountIn_formatted = numberWithCommas(indexObj.amountIn);
        indexObj.tax_formatted = numberWithCommas(indexObj.tax);
        indexObj.discount_formatted = numberWithCommas(indexObj.discount);
        for (let c = 0; c < indexObj.itemName.length; c++) {
          const index_name = indexObj.itemName[c];
          const index_codes = indexObj.itemCode[c];
          const index_quantity = indexObj.quantity[c];
          const final = {
            item_code: index_codes,
            item_name: index_name,
            item_quantity: index_quantity
          };
          tempArr.push(final);
        }
        if (
          indexObj.itemName.length > 1 &&
          indexObj.itemCode.length > 1 &&
          indexObj.quantity.length > 1
        ) {
          indexObj.children = tempArr;
          tempArr = [];
        } else if (
          indexObj.itemName.length < 1 &&
          indexObj.itemCode.length < 1 &&
          indexObj.quantity.length < 1
        ) {
          indexObj.itemName_formatted = '-';
          indexObj.itemCode_formatted = '-';
          indexObj.quantity_formatted = '-';
        } else {
          indexObj.itemName_formatted = indexObj.itemName;
          indexObj.itemCode_formatted = indexObj.itemCode;
          indexObj.quantity_formatted = indexObj.quantity;
        }
        //   const checkArray = Array.isArray(item.itemCode)
        //   if(checkArrayLength(item.itemCode)) {
        //   indexObj.itemCodes = (
        //     <Accordion count={item?.itemCode.length} icon='DownCaret'>
        //       {item?.itemCode.map((elem, index) => {
        //         return (
        //           <div style={{ display: 'flex' }}>
        //             <div className=''>{elem}</div>
        //           </div>
        //         );
        //       })}
        //     </Accordion>
        //   );
        //     } else {
        //       indexObj.itemCodes = item?.itemCode
        //     }
        //     if(checkArrayLength(item.itemName)) {
        //   indexObj.itemNames = (
        //     <Accordion count={item.itemName.length} icon='DownCaret'>
        //       {item?.itemName.map((elem, index) => {
        //         return (
        //           <div style={{ display: 'flex' }}>
        //             <div className=''>{elem}</div>
        //           </div>
        //         );
        //       })}
        //     </Accordion>
        //   );
        // } else {
        //   indexObj.itemNames = item?.itemName
        // }
        //   if(checkArrayLength(item.quantity)) {
        //   indexObj.quantityArr = (
        //     <Accordion count={item.itemName.length} icon='DownCaret'>
        //       {item?.quantity.map((elem, index) => {
        //         return (
        //           <div style={{ display: 'flex' }}>
        //             <div className=''>{elem}</div>
        //           </div>
        //         );
        //       })}
        //     </Accordion>
        //   );
        // } else {
        //   indexObj.quantityArr = item?.quantity
        // }
      });
      setTableData(data.slice(0, perPageNo));
      setCurrentPageNumber(currentPageIndex);
    } else {
      setTableData([]);
      // setCurrentPageNumber()
    }
  };

  const tableColumns = [
    {
      children: [
        {
          title: 'SKU',
          accessor: 'a',
        },
        {
          title: 'SKU',
          accessor: 'item_code',
        },
        {
          title: 'Product Name',
          accessor: 'item_name',
        },
        {
          title: 'Quantity',
          accessor: 'item_quantity',
        },
      ]
    },
    {
      title: 'SKU',
      accessor: 'itemCode_formatted',
    },
    {
      title: 'Product Name',
      accessor: 'itemName_formatted',
    },
    {
      title: 'Quantity',
      accessor: 'quantity_formatted',
    },
    {
      title: 'Sales Channel',
      accessor: 'salesChannel',
    },
    {
      title: 'Sale Price',
      accessor: 'amountEx_formatted',
    },
    {
      title: 'Discount Price',
      accessor: 'amountIn_formatted',
    },
    {
      title: 'Tax',
      accessor: 'tax_formatted',
    },
    {
      title: 'Discount',
      accessor: 'discount_formatted',
    },
    {
      title: 'Store',
      accessor: 'store',
    },
  ];

  let totalRecordsLength = paginationData;

  function isEmptyTableData(data) {
    return data.length === 0;
  }

  const handlePagination = async (id) => {
    onClickPageChanger(id);
  };

  return (
    <Table
      data={tableData}
      columns={tableColumns}
      showPagination={true}
      totalRecords={totalRecordsLength}
      perPage={perPageNo}
      handlePagination={handlePagination}
      loading={tableDataLoading}
      activePageNumber={currentPageNumber}
      render={({ data }) => {
        return isEmptyTableData(data) && !tableDataLoading ? (
          <tbody>
            <tr>
              <td colSpan={tableColumns.length + 1}>
                <div className='table-no-search-data'>NO RESULTS FOUND</div>
              </td>
            </tr>
          </tbody>
        ) : null;
      }}
    />
  );
};

export default ItemWiseReportTable;
