import React, { useEffect, useState } from 'react';
import { Accordion, Table } from '@teamfabric/copilot-ui';

const WastageReportTable = ({
  paginationData,
  onClickPageChanger,
  data,
  tableDataLoading,
  currentPageIndex,
}) => {
  const [tableData, setTableData] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  let perPageNo = 10;

  useEffect(() => {
    if (data && data.length > 0) {
      renderData();
    }
  }, [currentPageIndex, data, tableDataLoading]);

  const checkArrayLength = (array) => Array.isArray(array);
  const renderData = () => {
    if (data && data.length > 0) {
      data.forEach((item, index) => {
        let indexObj = data[index];
        let temp = [];
        if(!Boolean(indexObj.customer)){
          indexObj.customer = '-'
        }
        for (let a = 0; a < indexObj.itemCode.length; a++) {
          let item_Codes = indexObj.itemCode[a];
          let item_names = indexObj.itemName[a];
          let item_orderedQuantity = indexObj.orderedQuantity[a];
          let item_wastage = indexObj.wastage[a];
          let final = {
            item_Codes,
            item_names,
            item_orderedQuantity,
            item_wastage,
          };
          console.log(final);
          temp.push(final)
        }
        if (indexObj.itemName.length > 1 && indexObj.itemCode.length > 1 && indexObj.orderedQuantity.length> 1 && indexObj.wastage.length> 1) {
          indexObj.children = temp;
          temp = [];
        } else if (
          indexObj.itemName.length < 1 &&
          indexObj.itemCode.length < 1 &&
          indexObj.orderedQuantity.length < 1 && indexObj.wastage.length < 1
        ) {
          indexObj.itemNames = '-';
          indexObj.itemCodes = '-';
          indexObj.orderQuantityArr = '-';
          indexObj.wastageArr = '-';
        } else {
          indexObj.itemNames = indexObj.itemName;
          indexObj.itemCodes = indexObj.itemCode;
          indexObj.orderQuantityArr = indexObj.orderedQuantity;
          indexObj.wastageArr = indexObj.wastage;
        }

        // if (checkArrayLength(item.itemCode)) {
        //   indexObj.itemCodes = (
        //     <Accordion count={item?.itemCode.length} icon='DownCaret'>
        //       {item?.itemCode.map((elem, index) => {
        //         return (
        //           <div style={{ display: 'flex' }}>
        //             <div className=''>{elem}</div>
        //           </div>
        //         );
        //       })}
        //     </Accordion>
        //   );
        // } else {
        //   indexObj.itemCodes = item?.itemCode;
        // }
        // if (checkArrayLength(item.itemName)) {
        //   indexObj.itemNames = (
        //     <Accordion count={item.itemName.length} icon='DownCaret'>
        //       {item?.itemName.map((elem, index) => {
        //         return (
        //           <div style={{ display: 'flex' }}>
        //             <div className=''>{elem}</div>
        //           </div>
        //         );
        //       })}
        //     </Accordion>
        //   );
        // } else {
        //   indexObj.itemNames = item?.itemName;
        // }
        // if (checkArrayLength(item.orderedQuantity)) {
        //   indexObj.orderQuantityArr = (
        //     <Accordion count={item.orderedQuantity.length} icon='DownCaret'>
        //       {item?.orderedQuantity.map((elem, index) => {
        //         return (
        //           <div style={{ display: 'flex' }}>
        //             <div className=''>{elem}</div>
        //           </div>
        //         );
        //       })}
        //     </Accordion>
        //   );
        // } else {
        //   indexObj.orderQuantityArr = item?.orderedQuantity;
        // }
        // if (checkArrayLength(item.deliveredQuantity)) {
        //   indexObj.deliveredQuantityArr = (
        //     <Accordion count={item.deliveredQuantity.length} icon='DownCaret'>
        //       {item?.deliveredQuantity.map((elem, index) => {
        //         return (
        //           <div style={{ display: 'flex' }}>
        //             <div className=''>{elem}</div>
        //           </div>
        //         );
        //       })}
        //     </Accordion>
        //   );
        // } else {
        //   indexObj.deliveredQuantityArr = item?.deliveredQuantity;
        // }
        // if (checkArrayLength(item.quantity)) {
        //   indexObj.quantityArr = (
        //     <Accordion count={item.quantity.length} icon='DownCaret'>
        //       {item?.quantity.map((elem, index) => {
        //         return (
        //           <div style={{ display: 'flex' }}>
        //             <div className=''>{elem}</div>
        //           </div>
        //         );
        //       })}
        //     </Accordion>
        //   );
        // } else {
        //   indexObj.quantityArr = item?.quantity;
        // }
        // if (checkArrayLength(item.wastage)) {
        //   indexObj.wastageArr = (
        //     <Accordion count={item.wastage.length} icon='DownCaret'>
        //       {item?.wastage.map((elem, index) => {
        //         return (
        //           <div style={{ display: 'flex' }}>
        //             <div className=''>{elem}</div>
        //           </div>
        //         );
        //       })}
        //     </Accordion>
        //   );
        // } else {
        //   indexObj.wastageArr = item?.wastage;
        // }
      });
      setTableData(data.slice(0, perPageNo));
      setCurrentPageNumber(currentPageIndex);
    } else {
      setTableData([]);
      // setCurrentPageNumber()
    }
  };

  const tableColumns = [
    { children: [
      {
        title: '',
        accessor: 'add',
      },
      {
        title: '',
        accessor: 'add',
      },
      {
        title: 'SKU',
        accessor: 'item_Codes',
      },
      {
        title: 'Order Name',
        accessor: 'item_names',
      },
      {
        title: 'Order Quantity',
        accessor: 'item_orderedQuantity',
      },
      {
        title: '',
        accessor: 'add',
      },
      {
        title: 'Wastage',
        accessor: 'item_wastage',
      },
    ],
  },
    {
      title: 'Order No.',
      accessor: 'orderNo',
    },
    {
      title: 'SKU',
      accessor: 'itemCodes',
    },
    {
      title: 'Product Name',
      accessor: 'itemNames',
    },
    {
      title: 'Order Quantity',
      accessor: 'orderQuantityArr',
    },
    // {
    //   title: 'Delivered Quantity',
    //   accessor: 'deliveredQuantityArr',
    // },
    {
      title: 'Customer',
      accessor: 'customer',
    },
    {
      title: 'Wastage',
      accessor: 'wastageArr',
    },
    {
      title: 'Store',
      accessor: 'store',
    },
  ];

  let totalRecordsLength = paginationData;

  function isEmptyTableData(data) {
    return data.length === 0;
  }

  const handlePagination = async (id) => {
    onClickPageChanger(id);
  };

  return (
    <Table
      data={tableData}
      columns={tableColumns}
      showPagination={true}
      totalRecords={totalRecordsLength}
      perPage={perPageNo}
      handlePagination={handlePagination}
      loading={tableDataLoading}
      activePageNumber={currentPageNumber}
      render={({ data }) => {
        return isEmptyTableData(data) && !tableDataLoading ? (
          <tbody>
            <tr>
              <td colSpan={tableColumns.length + 1}>
                <div className='table-no-search-data'>NO RESULTS FOUND</div>
              </td>
            </tr>
          </tbody>
        ) : null;
      }}
    />
  );
};

export default WastageReportTable;
