import React, { useEffect, useState, useReducer } from "react";

// components
import CustomButtonWithIcon from "../../../atoms/button_with_icon";
import SwitchOutlet from "../../../atoms/switch_outlet";
import PageTitle from "../../../organism/header";
import DateRangePicker from "../../../molecules/date_range_picker";
import moment from "moment";
import * as ReportsAPIUtils from "../../../../utils/api/reports-api-utils";
import * as ApiCallUtil from "../../../../utils/api/generic-api-utils";
import * as Helper from "../../../../utils/helpers/scripts";
import DOMPurify from "dompurify";
import { Dropdown, MultiSelectChips } from "@teamfabric/copilot-ui";
import { getDataFromLocalStorage } from "../../../../utils/local-storage/local-store-utils";
import constants from "../../../../utils/constants/constants";
import * as SetupApiUtil from "../../../../utils/api/setup-api-utils";
import * as CustomersApiUtil from "../../../../utils/api/customer-api-utils";
import CustomSearch from "../../../atoms/search";
import ButtonSearch from "../../../atoms/button_search";
import ItemWiseReportTable from "../../../organism/table/reports/item_wise/itemWiseReportTable";
import * as ProductsApiUtil from "../../../../utils/api/products-api-utils";
const formErrorsReducer = (state, event) => {
  return { ...state, ...event };
};
const initialFormErrorsValues = {
  salesChannelError: false,
  searchLevelError: false,
};
const ItemWiseReport = () => {
  const [datesRange, setDatesRange] = useState([]);
  const [selectedDatesRange, setselectedDatesRange] = useState([]);

  const [paginationLimit, setPaginationLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showSummaryTable, setShowSummaryTable] = useState(false);
  const [options, setOptions] = useState({
    salesChannel: {
      id: 1,
      name: "Offline",
    },
    searchLevel: {
      id: 2,
      name: "Brand",
    },
  });
  const [searchInput, setSearchInput] = useState("");
  const [formErrorsData, setFormErrorsData] = useReducer(
    formErrorsReducer,
    initialFormErrorsValues
  );
  const { salesChannelError, searchLevelError } = formErrorsData;
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStores] = useState([]);
  const [storeError, setStoreError] = useState(false);
  const [customerId, setCustomerId] = useState("all");
  let selectedDates = [];
  const { salesChannel, searchLevel } = options;
  const dateFormat = "YYYY-MM-DD";

  const userAuth = ApiCallUtil.getUserAuth();
  const isAdmin =
    parseInt(userAuth?.user_info?.is_super) === Number(1) ? true : false;

  const handleRangePicker = (values) => {
    if (values) {
      let startDate = values[0]
        ? moment(values[0]).format(dateFormat)
        : moment(new Date()).format(dateFormat);
      let endDate = values[1]
        ? moment(values[1]).format(dateFormat)
        : moment(new Date()).format(dateFormat);
      selectedDates[0] = startDate;
      selectedDates[1] = endDate;
      setDatesRange(values);
    }
  };

  const fetchSalesHistoryWithDateRange = (e) => {
    let dates = [...datesRange];
    setselectedDatesRange(dates);
  };

  const startDate = selectedDatesRange[0]
    ? selectedDatesRange[0]
    : moment(new Date()).format(dateFormat);

  const endDate = selectedDatesRange[1]
    ? selectedDatesRange[1]
    : moment(new Date()).format(dateFormat);
  let mounted = true;

  useEffect(() => {
    resetData();
    let validationPass = true;

    if (!salesChannel?.name) {
      setFormErrorsData({ salesChannelError: true });
      validationPass = false;
    }
    if (!searchLevel?.name) {
      setFormErrorsData({ searchLevelError: true });
      validationPass = false;
    }
    if (!selectedStore.length && searchLevel.name !== "Brand") {
      setStoreError(true);
      validationPass = false;
    }
    if (validationPass) {
      setFormErrorsData(initialFormErrorsValues);
      fetchItemWiseReport(currentPage, paginationLimit);
    }

    return () => {
      mounted = false;
    };
  }, [selectedDatesRange]);

  const filter = {
    startDate,
    endDate,
    salesChannel: salesChannel?.name.toLowerCase(),
    ...(isAdmin && {searchLevel: searchLevel?.name.toLowerCase()}),
  };
  const resetData = () => {
    setData([]);
    setCurrentPage(1);
    setPaginationData(0);
  };

  const fetchItemWiseReport = async (page, pagination, productID) => {
    document.getElementById("app-loader-container").style.display = "block";
    setLoading(true);
    let storeIds = null;
    if (selectedStore.length > 0) {
      storeIds = selectedStore.map((store) => {
        return store.id;
      });
    }
    let newFilter = {
      ...filter,
      ...(storeIds && { storeIds }),
    };

    const checkProductId = productID ? productID : "all";
    console.log(productID);
    const customerDetailsResponse = await ReportsAPIUtils.itemWiseReport(
      newFilter,
      page,
      pagination,
      checkProductId
    );
    if (customerDetailsResponse.hasError) {
      setLoading(false);
      setShowSummaryTable(false);
      document.getElementById("app-loader-container").style.display = "none";
      showAlertUi(true, customerDetailsResponse.errorMessage);
    } else {
      setData(customerDetailsResponse?.data);
      setPaginationData(customerDetailsResponse?.totalItems);
      document.getElementById("app-loader-container").style.display = "none";
      setLoading(false);
    }
    document.getElementById("app-loader-container").style.display = "none";
    setLoading(false);
  };

  const showAlertUi = (show, errorText) => {
    return Helper.showAppAlertUiContent(show, errorText);
  };

  const handlePageChange = (currentPg) => {
    setCurrentPage(currentPg);
    setLoading(true);
    fetchItemWiseReport(currentPg, paginationLimit);
  };
  const downloadCSV = async () => {
    document.getElementById("app-loader-container").style.display = "block";
    let storeIds = null;
    if (selectedStore.length > 0) {
      storeIds = selectedStore.map((store) => {
        return store.id;
      });
    }
    let newFilter = {
      ...filter,
      ...(storeIds && { storeIds }),
    };
    const type = "download";
    const downloadResponse = await ReportsAPIUtils.itemWiseReport(
      newFilter,
      currentPage,
      paginationLimit,
      customerId,
      type
    );
    console.log(downloadResponse);
    if (downloadResponse.hasError) {
      document.getElementById("app-loader-container").style.display = "none";
      showAlertUi(true, downloadResponse.errorMessage);
    }
    if (downloadResponse.success) {
      if (mounted) {
        const { link } = downloadResponse;
        let a = document.createElement("a");
        a.href = link;
        a.download = "customer_wise" + new Date().toUTCString() + ".csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
        document.getElementById("app-loader-container").style.display = "none";
      }
    }
    document.getElementById("app-loader-container").style.display = "none";
  };
  const printOrderWise = () => {
    const previewSalesHtml = document.getElementById("printTable").innerHTML;
    const doc =
      '<html><head><title></title><link rel="stylesheet" type="text/css" href="css/print.css" /></head><body onload="window.print(); window.close();">' +
      previewSalesHtml +
      "</body></html>";

    const popupWin = window.open(
      "",
      "_blank",
      "toolbar=no,scrollbars=yes,resizable=yes,top=100,left=400,width=500,height=500"
    );
    const clean = DOMPurify.sanitize(doc);
    popupWin.document.open();

    popupWin.document.write(clean);
    popupWin.document.close();
  };
  const salesChannelsOptions = [
    {
      id: 1,
      name: "Offline",
    },
    {
      id: 2,
      name: "Online",
    },
  ];
  const searchLevelOptionsAdmin = [
    {
      id: 1,
      name: "Brand",
    },
    {
      id: 2,
      name: "Store",
    },
  ];
  const searchLevelOptionsUser = [
    {
      id: 1,
      name: "Brand",
    },
  ];

  const handleSalesChannelSelect = (option) => {
    const Name = option?.name;
    let index;
    const sales = ["Offline", "Online"];
    if (Name === "Offline") {
      index = 0;
    }
    if (Name === "Online") {
      index = 1;
    }
    const name = Boolean(sales[index]) ? "salesChannel" : "searchLevel";
    setOptions({
      ...options,
      [name]: option,
    });
    let errorName = name + "Error";
    setFormErrorsData({ [errorName]: false });
    if (name === "searchLevel" && Name === "Brand") {
      setSelectedStores([]);
      setStoreError(false);
    }
  };
  let loginCacheData = null;
  useEffect(() => {
    let readFromLocalStorage = getDataFromLocalStorage(
      constants.USER_DETAILS_KEY
    );
    readFromLocalStorage = readFromLocalStorage.data
      ? readFromLocalStorage.data
      : null;
    loginCacheData = readFromLocalStorage;
    if (loginCacheData) {
      fetchAllBrandsData(loginCacheData);
    }
  }, []);
  const flattenArray = (arr) => {
    return arr.reduce((prevValue, currValue) => {
      return prevValue.concat(
        Array.isArray(currValue) ? flattenArray(currValue) : currValue
      );
    }, []);
  };
  const fetchAllBrandsData = async (localStorageData) => {
    document.getElementById("app-loader-container").style.display = "block";
    const brandsViewResponse = await SetupApiUtil.viewUserById(
      localStorageData?.user_info?.user_id
    );
    if (brandsViewResponse.hasError) {
      setLoading(false);
      document.getElementById("app-loader-container").style.display = "none";
      showAlertUi(true, brandsViewResponse.errorMessage);
    } else {
      if (localStorageData) {
        let brandsList = brandsViewResponse?.data[0];
        brandsList = brandsList?.brand_info;
        console.log(brandsList);
        const storesArray = brandsList.map((brand) => {
          return brand?.stores.map(({ id, name }) => {
            return {
              label: name,
              id,
              value: name,
            };
          });
        });
        setStores(flattenArray(storesArray));
        setLoading(false);
        document.getElementById("app-loader-container").style.display = "none";
      }
    }
  };
  const handleStoreSelect = (store) => {
    setSelectedStores(store);
    setStoreError(false);
  };
  const onSearch = async (inputValue) => {
    let searchValue = inputValue.toLowerCase();
    if (inputValue === "") {
      // if empty value
      setData(null);
      setSearchInput("");
      setLoading(true);
      await fetchItemWiseReport(currentPage, paginationLimit);
      setCustomerId("all");
      return;
    }
    searchCustomer(searchValue);
  };
  const searchCustomer = async (searchValue) => {
    setLoading(true);
    const productsSearchResponse = await ProductsApiUtil.searchProductsMain(
      Helper.genericSearchPageLimit,
      Helper.genericSearchPageNumber,
      searchValue
    );
    console.log(productsSearchResponse);
    if (productsSearchResponse.hasError) {
      setLoading(false);
      setData(null);
      showAlertUi(true, productsSearchResponse.errorMessage);
      return;
    } else {
      const product = productsSearchResponse.products.data[0];
      const productId = product?.id;
      setCustomerId(productId);
      await fetchItemWiseReport(currentPage, paginationLimit, productId);

      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <section className="page sales_summary">
      <div className="page__top">
        <SwitchOutlet />
      </div>

      <PageTitle title="Item Wise" />

      <div className="page__buttons">
        <CustomButtonWithIcon
          onClick={downloadCSV}
          text="Download"
          iconName="Download"
          // disabled={!showSummaryTable}
        />

        {/* <CustomButtonWithIcon
          onClick={printOrderWise}
          text='Print Overview'
          disabled={!showSummaryTable}
        /> */}
      </div>
      <div className="page__search">
        <CustomSearch
          placeholder="Search by product name"
          onChange={(value) => setSearchInput(value)}
          onClearSearch={onSearch}
          handleEnterSearch={() => onSearch(searchInput)}
          inputValue={searchInput}
        />
        <ButtonSearch
          text="Search"
          clickHandler={() => onSearch(searchInput)}
          disabled={!Boolean(searchInput)}
        />
      </div>
      <div className="page__body">
        <div className="page__date_picker">
          <DateRangePicker
            startDateLabel="Start Date"
            endDateLabel="End Date"
            isFilter={true}
            onCalenderDateSelect={handleRangePicker}
            onFetchButtonClick={fetchSalesHistoryWithDateRange}
          />
        </div>

        <div className="page__search_filters">
          <Dropdown
            // className='form-dropdown-required'
            onSelect={handleSalesChannelSelect}
            options={salesChannelsOptions}
            titleLabel="Sales Channel"
            value={salesChannel}
            width="100%"
            errorMessage="Field Is Required"
            errorState={salesChannelError}
          />
          {
            isAdmin && (
              <Dropdown
            // className='form-dropdown-required'
            onSelect={handleSalesChannelSelect}
            options={
              Boolean(isAdmin)
                ? searchLevelOptionsAdmin
                : searchLevelOptionsUser
            }
            titleLabel="search Level"
            value={searchLevel}
            width="100%"
            errorMessage="Field Is Required"
            errorState={searchLevelError}
          />
            )
          }
         
          <div>
            {searchLevel.name === "Store" && isAdmin && (
              <MultiSelectChips
                dropdownOptions={stores}
                minimumChips={5}
                onChange={handleStoreSelect}
                showClearChipsButton
                label={"Select a store"}
                value={selectedStore}
                chipsClearHandler={() => setSelectedStores([])}
              />
            )}
            {storeError && <p className="error_msg">please select a store.</p>}
          </div>
        </div>

        <div className="page__table t-summary u_accordion_border">
          <ItemWiseReportTable
            data={data}
            paginationData={paginationData}
            tableDataLoading={loading}
            onClickPageChanger={handlePageChange}
            currentPageIndex={currentPage}
          />
        </div>
      </div>
    </section>
  );
};

export default ItemWiseReport;
