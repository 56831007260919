import React, { useState, useEffect, } from "react";
import { Button } from "@teamfabric/copilot-ui";

// components
import ButtonBack from "../../../atoms/button_back";
import * as ProductsApiUtil from '../../../../utils/api/products-api-utils';


import * as Helpers from "../../../../utils/helpers/scripts";
import PageTitle from "../../../organism/header";
import SwitchOutlet from "../../../atoms/switch_outlet";
import { useHistory, useLocation } from "react-router-dom";



function DeleteProduct(props) {
    const history = useHistory();
    const location = useLocation();
    const [productName, setProductName] = useState("");
    const [productSku, setProductSku] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const { match = {} } = props;
    const { product_id = {} } = match !== undefined && match.params;

    let mounted = true;
    useEffect(() => {
        window.scrollTo(0, 0);
        if (product_id !== undefined) { 
          setProductName(location.state.product_name);
          setProductSku(location.state.product_sku);
        }
        else {
            return popPage();
        }

        return () => {
            mounted = false;
        }

    }, [location]);


    const onFormSubmit = async (event) => {
        event.preventDefault();

            document.getElementById('app-loader-container').style.display = "block";
            const productDeleteResponse = await ProductsApiUtil.deleteProduct(product_id);
            if (productDeleteResponse.hasError) {
                setButtonDisabled(false);
                document.getElementById('app-loader-container').style.display = "none";
                showAlertUi(true, productDeleteResponse.errorMessage);
            }
            else {
                if (mounted) {     //imp if unmounted
                    document.getElementById('app-loader-container').style.display = "none";
                    setTimeout(() => {
                        history.push({
                            pathname: '/products',
                        });
                    }, 500);
                }
            }

    };


    const showAlertUi = (show, errorText) => {
        Helpers.showAppAlertUiContent(show, errorText);
    }


    const handleCancel = (event) => {
        event.preventDefault();
        history.push({
            pathname: '/products',
        });
    };

    const popPage = () => {
        history.goBack();
    };

    const delayPopPage = () => {
        setTimeout(() => {
            history.goBack();
        }, 2000);
    };

    return (

        <div className="page">
            <div className="page__top">
                <SwitchOutlet />
                <ButtonBack text="Back to Products" link="/products" />
            </div>

            <PageTitle title="Delete Product" />
            <div className="page__body">
                <section className="page__content">
                    <form className="form" onSubmit={onFormSubmit}>
                        <fieldset className="form__fieldset">
                            <div className="form__row">
                                <div className="item-delete-content">
                                    Do you really want to delete '{
                                        productName && productName} . (<span className="product-delete-sku-highlight"> SKU: <small>{productSku}</small> </span> )'?
                                </div>
                            </div>
                            <div className="form__row footer-delete-btns">
                                <Button
                                    className="delete-confirm-button"
                                    size="small"
                                    isPrimary={true}
                                    text="Confirm"
                                    disabled={buttonDisabled}
                                />
                                <Button
                                    size="small"
                                    isPrimary={false}
                                    text="Cancel"
                                    onClick={handleCancel}
                                />
                            </div>
                        </fieldset>
                    </form>
                </section>
            </div>
        </div>
    );
}

export default DeleteProduct;
