import moment from 'moment';

const format = "YYYY-MM-DD";
const today = moment().format(format);
const yesterday = moment().subtract(1, 'days').format(format);
const startOfLast7Days = moment().subtract(7, 'days').format(format);
const startOfLast30Days = moment().subtract(30, 'days').format(format);
const startOfCurrentMonth = moment().startOf('month').format(format);
const endOfCurrentMonth = moment().endOf('month').format(format);
const startOfLastYear = moment().subtract(1, 'year').format(format);

export const filterOptions = [
  {
    id: 0,
    name: "Custom",
    startDate: today,
    endDate: today,
  },
  {
    id: 1,
    name: "Today",
    startDate: today,
    endDate: today,
  },
  {
    id: 2,
    name: "Yesterday",
    startDate: yesterday,
    endDate: yesterday,
  },
  {
    id: 3,
    name: "Last 7 days",
    startDate: startOfLast7Days,
    endDate: today,
  },
  {
    id: 3,
    name: "Last 30 days",
    startDate: startOfLast30Days,
    endDate: today,
  },
  {
    id: 4,
    name: "This Month",
    startDate: startOfCurrentMonth,
    endDate: endOfCurrentMonth,
  },
  {
    id: 5,
    name: "Last Year",
    startDate: startOfLastYear,
    endDate: today,
  },
];


export const hourlyFilterOptions = [
  {
    id: 0,
    name: "Hours",
    value: "no"
  },
  {
    id: 1,
    name: "08 AM - 09 AM",
    value: "08AM-09AM"
  },
  {
    id: 2,
    name: "09 AM - 10 AM",
    value: "09AM-10AM"
  },
  {
    id: 3,
    name: "10 AM - 11 AM",
    value: "10AM-11AM"
  },
  {
    id: 4,
    name: "11 AM - 12 PM",
    value: "11AM-12PM"
  },
  {
    id: 5,
    name: "12 PM - 01 PM",
    value: "12PM-01PM"
  },
  {
    id: 6,
    name: "01 PM - 02 PM",
    value: "01PM-02PM"
  },
  {
    id: 7,
    name: "02 PM - 03 PM",
    value: "02PM-03PM"
  },
  {
    id: 8,
    name: "03 PM - 04 PM",
    value: "03PM-04PM"
  },
  {
    id: 9,
    name: "04 PM - 05 PM",
    value: "04PM-05PM"
  },
  {
    id: 10,
    name: "05 PM - 06 PM",
    value: "05PM-06PM"
  },
  {
    id: 11,
    name: "06 PM - 07 PM",
    value: "06PM-07PM"
  },
  {
    id: 12,
    name: "07 PM - 08 PM",
    value: "07PM-08PM"
  },
  {
    id: 13,
    name: "08 PM - 09 PM",
    value: "08PM-09PM"
  },
  {
    id: 14,
    name: "09 PM - 10 PM",
    value: "09PM-10PM"
  },
  {
    id: 15,
    name: "10 PM - 11 PM",
    value: "10PM-11PM"
  },
  {
    id: 16,
    name: "11 PM - 12 AM",
    value: "11PM-12AM"
  },
  {
    id: 17,
    name: "12 AM - 01 AM",
    value: "12AM-01AM"
  },
  {
    id: 18,
    name: "01 AM - 02 AM",
    value: "01AM-02AM"
  },
  {
    id: 19,
    name: "02 AM - 03 AM",
    value: "02AM-03AM"
  },
  {
    id: 20,
    name: "03 AM - 04 AM",
    value: "03AM-04AM"
  },
  {
    id: 21,
    name: "04 AM - 05 AM",
    value: "04AM-05AM"
  },
  {
    id: 22,
    name: "05 AM - 06 AM",
    value: "05AM-06AM"
  },
  {
    id: 23,
    name: "06 AM - 07 AM",
    value: "06AM-07AM"
  },
  {
    id: 24,
    name: "07 AM - 08 AM",
    value: "07AM-08AM"
  },
];
