import React from "react";
import PropTypes from "prop-types";
import { Button } from "@teamfabric/copilot-ui";

function PageTitle({ title, isButton, btnText, size, handleClick }) {
  return (
    <div className="page__title">
      <h2 className="heading heading--primary">{title}</h2>
      {isButton && (
        <Button
          text={btnText || "Add"}
          iconName="Add"
          size={size || "medium"}
          onClick={handleClick}
        />
      )}
    </div>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string,
};

export default PageTitle;
