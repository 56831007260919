import React, { useState, useEffect } from "react";
import { Snackbar } from "@teamfabric/copilot-ui";

const RenderConnectionAlertUi = ({ isOnline }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [text, setText] = useState("");
  const [kind, setKind] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");

  useEffect(() => {
    let isMounted = true;

    if (showAlert && isOnline) {
        setKind("success");
        setBackgroundColor("rgb(59, 201, 149)");
        setText("Your internet connection was restored");
    } else {
        setKind("alert");
        setBackgroundColor("#ff5733");
        setText("You are currently offline. Please check your internet connection");
    }

    if (isOnline && showAlert && isMounted) {
      setShowAlert(true);
      
      setTimeout(() => {
        if (isMounted) {
          setShowAlert(false);
        }
      }, 4000);
    }

    if (!isOnline && isMounted) {
      setShowAlert(true);
      setTimeout(() => {
        if (isMounted) {
          setShowAlert(false);
        }
      }, 4000);
    }

    return () => {
      isMounted = false;
    };
  }, [isOnline]);

  return (
    <div className="app-connection-alert">
      <Snackbar
        className="alert-content"
        label={text}
        kind={kind}
        onDismiss={function noRefCheck(){}}
        show={showAlert}
        width="100%"
        height="60px"
        textColor="#fff"
        backgroundColor={backgroundColor}
        size={"small"}
        withIcon={true}
      />
    </div>
  );
};

export default RenderConnectionAlertUi;
